// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Env } from 'src/app/enums/environment.enum';

export const environment = {
  id: Env.DEV,
  production: false,
  baseUrls: {
    httpLoaderPrefix: './assets/i18n/',
    httpLoaderSuffix: '.json',
    apiBaseUrl: 'https://ps1n9htsqj-vpce-09b66c79525ffc5e3.execute-api.eu-central-1.amazonaws.com/noprod-dev/backend/',
    authenticationUrl: {
      base: 'https://auth-noprod-dev.auth.eu-central-1.amazoncognito.com/oauth2/authorize?',
      options: {
        identity_provider: 'Azure',
        response_type: 'TOKEN',
        scope: 'aws.cognito.signin.user.admin+email+openid+profile',
        redirect_uri: window.location.protocol + '//' + window.location.host, //'https://app.noprod-dev.pdp.alperia.digital',
      }
    },
    refreshTokenUrl: {
      base: 'https://auth-noprod-dev.auth.eu-central-1.amazoncognito.com/oauth2/token'
    }
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
