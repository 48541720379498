import { Language } from "src/app/enums/language.enum";
import { AuctionDataType } from "src/app/enums/auction-data-type.enum";
import { UnitDataType } from "src/app/enums/unit-data-type.enum";
import { AuctionDataTypeList } from "src/app/interfaces/auction-data-type-list";
import { AlertThemes } from "src/app/enums/alert-themes.enum";
import { StorageKeys } from "src/app/enums/storage-keys";
import { AlertConfiguration } from "src/app/interfaces/alert-configuration";
import { UnitCode } from "src/app/enums/unit-codes.enum";
import { AuctionGuide, AuctionGuideGroup } from "src/app/interfaces/auction";
import { AuctionGuideType } from "src/app/enums/auction-guide.enum";
import { Tab } from "src/app/interfaces/tab";
import { DashboardTab } from "src/app/enums/dashboard-tab.enum";
import { ModalConfigHead } from "../interfaces/modal-config";

// VARIABLES

export const DEFAULT_LANGUAGE = Language.IT;
export const LANGUAGE_LIST = [...Object.values(Language)];
export const UNIT_ID_LINK_PREFIX = "up";
export const RELATIVE_LINK_SCROLL_OFFSET = 120;
export const REPORT_TABLE_ZERO_DATA_LABEL = "-";
export const REPORT_TABLE_NO_DATA_LABEL = "ND";
export const NOTE_DEFAULT_USER_NAME = "Operatore";
export const DATA_UPDATE_FREQUENCY = 60; // seconds
export const BDE_WARN_TIME = 15; // minutes
export const MIB_WARN_TIME = 30; // minutes
export const LOADER_CLASS_PREFIX = "loader";
export const BREAKPOINT_TABLET_MAX_WIDTH = "(max-width: 1499px)";
export const ALL_AUCTIONS_ID = "all";
export const LINK_CONFIG_KEY = "mainLink";
export const ALARMS_AUDIO_PATH = "assets/audio/alert.mp3";
export const URL_WITH_PROTOCOL_PATTERN = "^((http|https)://).{1,}$";

// COMPONENT CONFIGS

export const UNIT_LIST = [
  { order: 101, unitCode: UnitCode.CURON },
  { order: 102, unitCode: UnitCode.GLORENZA },
  { order: 104, unitCode: UnitCode.LASA },
  { order: 105, unitCode: UnitCode.CASTELBELLO },
  { order: 106, unitCode: UnitCode.NATURNO },
  { order: 107, unitCode: UnitCode.TEL },
  { order: 108, unitCode: UnitCode.MARLENGO },
  { order: 201, unitCode: UnitCode.FONTANA_BIANCA },
  { order: 202, unitCode: UnitCode.PRACOMUNE },
  { order: 203, unitCode: UnitCode.VALBURGA },
  { order: 204, unitCode: UnitCode.PANCRAZIO },
  { order: 205, unitCode: UnitCode.LANA },
  { order: 301, unitCode: UnitCode.LAPPAGO },
  { order: 302, unitCode: UnitCode.MOLINI },
  { order: 303, unitCode: UnitCode.BRUNICO },
  { order: 304, unitCode: UnitCode.PRATI },
  { order: 305, unitCode: UnitCode.BRESSANONE },
  { order: 401, unitCode: UnitCode.BARBIANO },
  { order: 402, unitCode: UnitCode.CARDANO },
  { order: 403, unitCode: UnitCode.PREMESA },
  { order: 404, unitCode: UnitCode.GARDENA },
  { order: 405, unitCode: UnitCode.SARENTINO },
  { order: 501, unitCode: UnitCode.FLORIANO_1 },
  { order: 501, unitCode: UnitCode.FLORIANO_2 },
];
export const auctionDataTypeList: AuctionDataTypeList[] = [
  {
    id: UnitDataType.PV,
    label: "PV",
    type: AuctionDataType.COMPARABLE,
  },
  {
    id: UnitDataType.PVM_PV,
    label: "PVM vs PV",
    type: AuctionDataType.COMPARABLE,
  },
  {
    id: UnitDataType.POWER,
    label: "Potenza",
    type: AuctionDataType.COMPARABLE,
  },
  {
    id: UnitDataType.P_MAX,
    label: "P Max",
    type: AuctionDataType.STANDARD,
  },
  {
    id: UnitDataType.CHART,
    label: "Chart",
    type: AuctionDataType.GRAPH,
  },
];
export const dashboardViewList: Tab[] = [
  {
    id: DashboardTab.DASHBOARD,
    label: "DASHBOARD-TAB:dashboard",
  },
  {
    id: DashboardTab.BDE,
    label: "DASHBOARD-TAB:bde",
  },
  {
    id: DashboardTab.MIB,
    label: "DASHBOARD-TAB:mib",
  },
];
export const dashboardInfoAlertConfig: AlertConfiguration = {
  theme: AlertThemes.INFO,
  iconID: "icon-rocket",
  message: "DASHBOARD-ALERT:generic-info-content",
  hasRememberCheck: true,
  rememberCheckMessage: "DASHBOARD-ALERT:generic-info-remember",
  rememberCheckStorageKey: StorageKeys.DASHBOARD_TIP_1_CHECKED,
};
export const dashboardConnectionErrorAlertConfig: AlertConfiguration = {
  theme: AlertThemes.ERROR,
  message: "DASHBOARD-ALERT:connection-error-content",
};
export const auctionConnectionErrorAlertConfig: AlertConfiguration = {
  theme: AlertThemes.ERROR,
  message: dashboardConnectionErrorAlertConfig.message,
};
export const newNoteSuccessAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.SUCCESS,
  message: "NOTEBAR-ALERT:new-note-success",
  fadeTime: "fast",
  autocloseTimeout: 6,
};
export const newNoteErrorAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.ERROR,
  message: "NOTEBAR-ALERT:new-note-error",
  fadeTime: "fast",
  autocloseTimeout: 10,
};
export const deleteNoteSuccessAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.SUCCESS,
  message: "NOTEBAR-ALERT:delete-note-success",
  fadeTime: "fast",
  autocloseTimeout: 6,
};
export const deleteNoteErrorAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.ERROR,
  message: "NOTEBAR-ALERT:delete-note-error",
  fadeTime: "fast",
  autocloseTimeout: 10,
};
export const editNoteSuccessAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.SUCCESS,
  message: "NOTEBAR-ALERT:edit-note-success",
  fadeTime: "fast",
  autocloseTimeout: 6,
};
export const editNoteErrorAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.ERROR,
  message: "NOTEBAR-ALERT:edit-note-error",
  fadeTime: "fast",
  autocloseTimeout: 10,
};
export const addAuctionLinkSuccessAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.SUCCESS,
  message: "AUCTION-CONFIG--ALERT:add-link-success",
  fadeTime: "fast",
  autocloseTimeout: 6,
};
export const addAuctionLinkErrorAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.ERROR,
  message: "AUCTION-CONFIG--ALERT:add-link-error",
  fadeTime: "fast",
  autocloseTimeout: 10,
};
export const deleteAuctionLinkSuccessAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.SUCCESS,
  message: "AUCTION-CONFIG--ALERT:delete-link-success",
  fadeTime: "fast",
  autocloseTimeout: 6,
};
export const deleteAuctionLinkErrorAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.ERROR,
  message: "AUCTION-CONFIG--ALERT:delete-link-error",
  fadeTime: "fast",
  autocloseTimeout: 10,
};
export const editUnitFlagSuccessAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.SUCCESS,
  message: "UNIT-CONFIG--ALERT:edit-flag-success",
  fadeTime: "fast",
  autocloseTimeout: 6,
};
export const editUnitFlagErrorAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.ERROR,
  message: "UNIT-CONFIG--ALERT:edit-flag-error",
  fadeTime: "fast",
  autocloseTimeout: 10,
};
export const addUnitLinkSuccessAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.SUCCESS,
  message: "UNIT-CONFIG--ALERT:add-link-success",
  fadeTime: "fast",
  autocloseTimeout: 6,
};
export const addUnitLinkErrorAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.ERROR,
  message: "UNIT-CONFIG--ALERT:add-link-error",
  fadeTime: "fast",
  autocloseTimeout: 10,
};
export const editUnitLinkSuccessAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.SUCCESS,
  message: "UNIT-CONFIG--ALERT:edit-link-success",
  fadeTime: "fast",
  autocloseTimeout: 6,
};
export const editUnitLinkErrorAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.ERROR,
  message: "UNIT-CONFIG--ALERT:edit-link-error",
  fadeTime: "fast",
  autocloseTimeout: 10,
};
export const deleteUnitLinkSuccessAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.SUCCESS,
  message: "UNIT-CONFIG--ALERT:delete-link-success",
  fadeTime: "fast",
  autocloseTimeout: 6,
};
export const deleteUnitLinkErrorAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.ERROR,
  message: "UNIT-CONFIG--ALERT:delete-link-error",
  fadeTime: "fast",
  autocloseTimeout: 10,
};
export const editUnitThresholdSuccessAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.SUCCESS,
  message: "UNIT-CONFIG--ALERT:edit-threshold-success",
  fadeTime: "fast",
  autocloseTimeout: 6,
};
export const editUnitThresholdErrorAlertConfiguration: AlertConfiguration = {
  theme: AlertThemes.ERROR,
  message: "UNIT-CONFIG--ALERT:edit-threshold-error",
  fadeTime: "fast",
  autocloseTimeout: 10,
};
export const chartQuarterlyFullLabels: string[] = [
  "00:00",
  "00:15",
  "00:30",
  "00:45",
  "01:00",
  "01:15",
  "01:30",
  "01:45",
  "02:00",
  "02:15",
  "02:30",
  "02:45",
  "03:00",
  "03:15",
  "03:30",
  "03:45",
  "04:00",
  "04:15",
  "04:30",
  "04:45",
  "05:00",
  "05:15",
  "05:30",
  "05:45",
  "06:00",
  "06:15",
  "06:30",
  "06:45",
  "07:00",
  "07:15",
  "07:30",
  "07:45",
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
  "20:15",
  "20:30",
  "20:45",
  "21:00",
  "21:15",
  "21:30",
  "21:45",
  "22:00",
  "22:15",
  "22:30",
  "22:45",
  "23:00",
  "23:15",
  "23:30",
  "23:45",
];
export const chartQuarterlyLabels: string[] = [
  "",
  "",
  "",
  "",
  "01:00",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "05:00",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "09:00",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "13:00",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "17:00",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "21:00",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "24:00",
];
export const chartHourlyFullLabels: string[] = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];
export const chartHourlyLabels: string[] = [
  "01:00",
  "",
  "",
  "",
  "",
  "05:00",
  "",
  "",
  "",
  "09:00",
  "",
  "",
  "",
  "13:00",
  "",
  "",
  "",
  "17:00",
  "",
  "",
  "",
  "21:00",
  "",
  "24:00",
];
export const auctionPvGuide: AuctionGuide[] = [
  {
    type: AuctionGuideType.NOMINAL,
    label: "AUCTION--GUIDE:pv-nominal",
    content: "#",
  },
  {
    type: AuctionGuideType.POSITIVE,
    label: "AUCTION--GUIDE:pv-positive",
    content: "#",
  },
  {
    type: AuctionGuideType.NEGATIVE,
    label: "AUCTION--GUIDE:pv-negative",
    content: "#",
  },
  {
    type: AuctionGuideType.NO_DATA,
    label: "AUCTION--GUIDE:pv-no_data",
    content: "ND",
  },
  {
    type: AuctionGuideType.PAST,
    label: "AUCTION--GUIDE:pv-past",
    content: "#",
  },
  {
    type: AuctionGuideType.MIB,
    label: "AUCTION--GUIDE:pv-mib",
    content: "#",
  },
];
export const auctionPvmGuide: AuctionGuide[] = [
  {
    type: AuctionGuideType.NOMINAL,
    label: "AUCTION--GUIDE:pvm-nominal",
    content: "#",
  },
  {
    type: AuctionGuideType.POSITIVE,
    label: "AUCTION--GUIDE:pvm-positive",
    content: "#",
  },
  {
    type: AuctionGuideType.NEGATIVE,
    label: "AUCTION--GUIDE:pvm-negative",
    content: "#",
  },
  {
    type: AuctionGuideType.NO_DATA,
    label: "AUCTION--GUIDE:pvm-no_data",
    content: "ND",
  },
  {
    type: AuctionGuideType.PAST,
    label: "AUCTION--GUIDE:pvm-past",
    content: "#",
  },
  {
    type: AuctionGuideType.MIB,
    label: "AUCTION--GUIDE:pvm-mib",
    content: "#",
  },
];
export const auctionPowerGuide: AuctionGuide[] = [
  {
    type: AuctionGuideType.NOMINAL,
    label: "AUCTION--GUIDE:power-nominal",
    content: "#",
  },
  {
    type: AuctionGuideType.POSITIVE,
    label: "AUCTION--GUIDE:power-positive",
    content: "#",
  },
  {
    type: AuctionGuideType.NEGATIVE,
    label: "AUCTION--GUIDE:power-negative",
    content: "#",
  },
  {
    type: AuctionGuideType.NO_DATA,
    label: "AUCTION--GUIDE:power-no_data",
    content: "ND",
  },
  {
    type: AuctionGuideType.PAST,
    label: "AUCTION--GUIDE:power-past",
    content: "#",
  },
  {
    type: AuctionGuideType.MIB,
    label: "AUCTION--GUIDE:power-mib",
    content: "#",
  },
];
export const auctionGuides: AuctionGuideGroup[] = [
  {
    tabID: UnitDataType.PV,
    guides: auctionPvGuide,
  },
  {
    tabID: UnitDataType.PVM_PV,
    guides: auctionPvmGuide,
  },
  {
    tabID: UnitDataType.PVM_P,
    guides: auctionPowerGuide,
  },
];
export const MODAL_CONFIG_HEADER: ModalConfigHead = {
  title: "MODAL-CONFIG:title",
  closeIconID: "icon-cross",
};
