export enum UnitCode {
  CURON = "UP_CURON_ME_1",
  GLORENZA = "UP_SLDGLRENZA_3",
  LASA = "UP_LASA_ME_1",
  CASTELBELLO = "UP_CASTELB.E_1",
  NATURNO = "UP_CNTRLNTRNO_11",
  TEL = "UP_CNTRALETEL_11",
  MARLENGO = "UP_MARLENG.M_1",
  FONTANA_BIANCA = "UP_FONTANA_B_1",
  PRACOMUNE = "UP_PRACOMUNE_1",
  VALBURGA = "UP_ULTIMO_1",
  PANCRAZIO = "UP_S.PANCRAZ_1",
  LANA = "UP_LANA_1",
  LAPPAGO = "UP_LAPPAGO_1",
  MOLINI = "UP_M.DI_TURE_1",
  BRUNICO = "UP_BRUNICO_M_1",
  PRATI = "UP_PRATI_ME_1",
  BRESSANONE = "UP_BRESSANON_1",
  FLORIANO_1 = "UP_S.FLORI.A_1",
  FLORIANO_2 = "UP_SFLORIANO_2",
  BARBIANO = "UP_P.GARD.ME_1",
  CARDANO = "UP_CARDANO_1",
  PREMESA = "UP_PREMESA_M_1",
  GARDENA = "UP_PONTE_GAR_1",
  SARENTINO = "UP_SARENTINO_1",
}
